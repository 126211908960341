import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setOrganization } from 'redux/slices/organization'
import { Link } from 'react-router-dom'
import TaskDetailModal from 'components/Page/Tasks/TaskDetailModal/TaskDetailModal'
import DefaultImage from 'components/Common/DefaultImage'
import { useRole } from 'hooks/useRole'
import { RouterChecker } from 'utils/RouteChecker'
import {useLazyGetAllTasksApiQuery} from 'redux/api/task'
import { setRefreshTask } from 'redux/slices/task'

const HeaderSearchBoxItem = ({ type, data }) => {
	const organization = useSelector(state => state.organization)
	const dispatch = useDispatch()
	const [modalStatus, setModalStatus] = useState(false)
	const isClient = useRole('Client')

	const refetchTasksList = () => {
		// getTasks({ ...filters, projectId: params?.projectId })
		getAllTasks()
		dispatch(setRefreshTask(false))
		window.location.reload()
	}
	const [getAllTasks] = useLazyGetAllTasksApiQuery()
	const updateListHandler = () => {
		if (RouterChecker('/dashboard/tasks')) {
			getAllTasks({})
			dispatch(setRefreshTask(true))
		} else {
			dispatch(setRefreshTask(true))
			refetchTasksList()
		}
	}

	return (
		<li className='border-b py-3 first:pt-0 last:pb-0 border-solid border-black border-opacity-5 last:border-0'>
			{type === 'task' ? (
				<button
					onClick={() => setModalStatus(isClient ? false : true)}
					className='flex items-center justify-between '>
					<div className='flex items-center'>
						{type === 'project' && (
							<img className='w-[22px] h-[22px] mr-2' src={data?.logo} />
						)}
						<span className='text-sm font-medium text-gray-700'>
							{' '}
							{data?.name}
						</span>
					</div>
					<span className='text-gray-400 text-sm'>
						{data?.organization?.name}
					</span>
				</button>
			) : (
				<a
					onClick={() => {
						type === 'project'
							? data?.organization?.id !== organization?.id &&
							  dispatch(setOrganization(data?.organization))
							: data?.project?.organization?.id !== organization?.id &&
							  dispatch(setOrganization(data?.project?.organization))
					}}
					className='flex items-center justify-between '
					href={
						type === 'project'
							? `/progress/${data?.id}`
							: `/progress/${data?.project?.id}/milestones?id=${data.id}#${data?.id}`
					}>
					<div className='flex items-center gap-2'>
						{type === 'project' && (
							<DefaultImage name={data?.name} src={data?.logo} size={22} fontSize={12} />

						)}
						<span className='text-sm font-medium text-gray-700'>
							{' '}
							{data?.name}
						</span>
					</div>
					<span className='text-gray-400 text-sm'>
						{data?.organization?.name}
					</span>
				</a>
				// <Link
				// 	onClick={() => {
				// 		type === 'project'
				// 			? data?.organization?.id !== organization?.id &&
				// 			  dispatch(setOrganization(data?.organization))
				// 			: data?.project?.organization?.id !== organization?.id &&
				// 			  dispatch(setOrganization(data?.project?.organization))
				// 	}}
				// 	className='flex items-center justify-between '
				// 	to={
				// 		type === 'project'
				// 			? `/progress/${data?.id}`
				// 			: `/progress/${data?.project?.id}/milestones?id=${data.id}#${data?.id}`
				// 	}>
				// 	<div className='flex items-center gap-2'>
				// 		{type === 'project' && (
				// 			<DefaultImage name={data?.name} src={data?.logo} size={22} fontSize={12} />

				// 		)}
				// 		<span className='text-sm font-medium text-gray-700'>
				// 			{' '}
				// 			{data?.name}
				// 		</span>
				// 	</div>
				// 	<span className='text-gray-400 text-sm'>
				// 		{data?.organization?.name}
				// 	</span>
				// </Link>
			)}
			{type === 'project' && (
				<ul className='flex mt-2 items-center gap-2 divide-x-2'>
					{type === 'milestone' && (
						<li className='text-[8px] font-light text-gray-700 '>
							{data?.name}
						</li>
					)}

					<li className=' pl-2 last:pl-0 '>
						<button className='flex gap-2 items-center' to={'/'}>
							<DefaultImage name={data?.project?.name} src={data?.project?.logo} size={12} fontSize={6} />
							<span className='text-[8px]  font-light text-gray-700 '>
								{data?.project?.name}
							</span>
						</button>
					</li>
				</ul>
			)}
			{modalStatus && (
				<TaskDetailModal isSubtask={data?.parent_id} updateList={updateListHandler} handler={() => setModalStatus(false)} data={data} />
			)}
		</li>
	)
}

HeaderSearchBoxItem.propTypes = {
	type: PropTypes.string
}

HeaderSearchBoxItem.defaultProps = {
	type: 'project'
}

export default HeaderSearchBoxItem
