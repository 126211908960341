import MagnifierIcon from 'components/Icons/MagnifierIcon'
import XIcon from 'components/Icons/XIcon'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { useEffect, useRef, useState } from 'react'
import HeaderSearchBoxItem from './HeaderSearchBoxItem'
import { SearchApi } from 'services/search'
import { useNavigate } from 'react-router-dom'
import Spinner from 'components/Common/Spinner'
import ListZeroPage from 'components/Common/ListZeroPage'
import { useRole } from 'hooks/useRole'

const HeaderSearchBox = () => {
	const isClient = useRole('Client')
	const [width] = useWindowSize()
	const wrapperRef = useRef(null)
	const [showSearchInput, setShowSearchInput] = useState(false)
	const [searchBoxCondition, setSearchBoxCondition] = useState(false)
	const [result, setResult] = useState()
	const navigate = useNavigate()
	let timer

	useEffect(() => {
		document.addEventListener('click', handleClick)
		return () => document.removeEventListener('click', handleClick)
		function handleClick(e) {
			if (wrapperRef && wrapperRef.current) {
				const ref = wrapperRef.current
				if (!ref.contains(e.target)) {
					setSearchBoxCondition(false)
				}
			}
		}
	}, [])
	const searchHandler = async _value => {
		if (_value.length === 0) {
			setSearchBoxCondition(false)
			setResult()
			return
		}
		setSearchBoxCondition(true)
		clearTimeout(timer)
		timer = setTimeout(() => {
			searchData(_value)
		}, 400)
	}

	const searchData = async _value => {
		if (_value?.length !== 0) {
			setSearchBoxCondition(true)
		}
		setResult('loading')
		try {
			let response = await SearchApi(navigate, _value)
			setResult(response.data.data)
		} catch (error) {
			console.log(error)
		}
	}
	return (
		<>
			<button
				onClick={() => setShowSearchInput(true)}
				className='h-11 items-center lg:hidden flex justify-center bg-gray-200 rounded-[10px]'
				style={{ minWidth: '2.75rem' }}
			>
				<MagnifierIcon />
			</button>
			<div
				ref={wrapperRef}
				style={{
					top: width <= 1024 ? (showSearchInput ? '10px' : '-100%') : '0px',
					width: `calc(100% - 40px)`
				}}
				className='lg:relative absolute lg:w-[50%]  '>
				<div className='w-full   relative '>
					<input
						onInput={e => searchHandler(e.target.value)}
						className='h-[52px] bg-gray-300 px-5 w-full placeholder:text-gray-350  placeholder:text-sm text-sm pl-[66px] placeholder:text-opacity-50 rounded-[10px]'
						placeholder={
							isClient
								? 'Search in Projects'
								: 'Search in Projects, Milestones, Teams ,...'
						}
					/>
					<span className='absolute text-gray-350 text-opacity-50 left-[30px] top-1/2 -translate-y-1/2'>
						<MagnifierIcon />
					</span>
					<button
						onClick={() => setShowSearchInput(false)}
						className='lg:hidden absolute right-5 top-1/2 -translate-y-1/2'>
						<XIcon color='#000' />
					</button>
				</div>

				{searchBoxCondition && (
					<div
						className={`absolute grid-cols-2 gap-5 max-h-[300px] overflow-auto  grid top-full mt-3  w-full bg-white shadow-custom  z-[52] rounded-[10px] py-4 px-6`}>
						{result === 'loading' ? (
							<div className='col-span-2 flex justify-center'>
								<Spinner show={true} scale={35} />
							</div>
						) : result !== 'loading' &&
						  result &&
						  Object.values(result).flat()?.length === 0 ? (
							<div className='col-span-2'>
								<ListZeroPage data={[]} isHorizontal />
							</div>
						) : (
							<>
								{result?.projects?.length !== 0 && (
									<div>
										<h6 className='text-[10px] text-black text-opacity-10 font-bold mb-1'>
											PROJECTS
										</h6>
										<ListZeroPage
											data={result?.projects}
											isHorizontal
											isFetching={result?.projects === 'loading'}
											isLoading={result?.projects === 'loading'}>
											<ul className='rounded-[10px] px-5 py-4 bg-gray-300'>
												{result?.projects?.map(item => (
													<HeaderSearchBoxItem data={item} type='project' />
												))}
											</ul>
										</ListZeroPage>
									</div>
								)}

								{result?.milestones?.length || result?.tasks?.length ? (
									<div
										className={`grid gap-5 ${
											!result?.projects?.length && 'col-span-2'
										} ${
											!result?.projects?.length &&
											result?.milestones?.length &&
											result?.tasks?.length
												? 'grid-cols-2'
												: null
										}`}>
										{result?.milestones?.length > 0 && (
											<div>
												<h6 className='text-[10px] text-black text-opacity-10 font-bold mb-1'>
													MILESTONES
												</h6>
												<ul className='rounded-[10px] px-5 py-4 bg-gray-300'>
													{result?.milestones?.map(item => (
														<HeaderSearchBoxItem data={item} type='milestone' />
													))}
												</ul>
											</div>
										)}
										{result?.tasks?.length > 0 && (
											<div>
												<h6 className='text-[10px] text-black text-opacity-10 font-bold mb-1'>
													TASKS
												</h6>
												<ul className='rounded-[10px] px-5 py-4 bg-gray-300'>
													{result?.tasks?.map(item => (
														<HeaderSearchBoxItem data={item} type='task' />
													))}
												</ul>
											</div>
										)}
									</div>
								) : null}
							</>
						)}
					</div>
				)}
			</div>
		</>
	)
}

export default HeaderSearchBox
