import MagnifierIcon from 'components/Icons/MagnifierIcon'
import React, { Fragment, useEffect } from 'react'
import ReactSelect, {components} from 'react-select'
import ErrorDisplay from './ErrorDisplay'
import XIcon from 'components/Icons/XIcon'

const SelectBox = ({
	isLoading,
	name,
	options,
	onChange,
	value,
	isDisabled,
	label,
	height,
	placeholder,
	withSearchIcon = false,
	grayBg = false,
	errors,
	className,
	containerClassName,
	ref
}) => {
	const { Option } = components;
	const IconOption = props => (
		<Option {...props} className='!bg-white !text-gray-700'>
			<div className='flex items-center justify-start gap-[6px]'>
		  		<div className='mr-2'>{props.data.icon && props.data.icon}</div>
		  		<div>{props.data.label}</div>
			</div>
		</Option>
	  );
	return (
		<div 
			className={`${containerClassName}`} 
			style={placeholder === 'Select Sort' ? { width : 'max-content' } : {}}
		>
			<label className='font-light flex !text-gray-700 text-sm mb-0.5'>
				{label}
			</label>
			<div
				style={{ height: height + 'px' }}
				className={`text-gray-350 text-xs relative  custom_select_box ${
					className && className
				} ${grayBg && 'bg_gray'} ${
					withSearchIcon && 'custom_select_box_with_search'
				} ${errors && 'custom_select_box_error'}`}>
				<ReactSelect
					isLoading={isLoading}
					onChange={e => onChange(name, e)}
					options={options}
					placeholder={placeholder}
					value={value}
					isDisabled={isDisabled}
					components={{ Option: IconOption }}
					ref={ref}
				/>
				{value && !isDisabled ? (
					<button
						onClick={() => onChange(name, null)}
						type='button'
						className='absolute right-10 top-1/2 -translate-y-1/2'>
						<XIcon color='#C10000' height={15} width={15} />
					</button>
				) : (
					null
				)}

				{withSearchIcon && (
					<span className='absolute text-gray-350 left-6 top-1/2 -translate-y-1/2'>
						<MagnifierIcon />
					</span>
				)}
			</div>
			<ErrorDisplay error={errors} />
		</div>
	)
}

export default SelectBox
