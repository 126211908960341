export const DownloadBlobFile = (data, fileName) => {
	const url = window.URL.createObjectURL(data)
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', fileName)
	document.body.appendChild(link)
	link.addEventListener('click', e => {
		e.stopPropagation()
	})
	link.click()
}
