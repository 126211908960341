import React from 'react'
import logo from 'assets/images/spanpm-logo-dark-grey.svg'

const MainLogo = () => {
	return (
		<div className='flex w-[170px] h-[52px]'>
			<img src={logo} alt='Span Logo'/>
		</div>
	)
}

export default MainLogo
