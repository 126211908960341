import ArrowIcon from 'components/Icons/ArrowIcon'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
	ChangeTaskProgressApi,
	GetTaskByTaskIdApi
} from 'services/dashboardServices/task'
import Spinner from './Spinner'
import { useLazyGetProjectTaskApiQuery } from 'redux/api/task'
import { toast } from 'react-toastify'

const ProgressDropDown = ({
	data,
	parentDetail,
	isSubtask,
	bigSize,
	position
}) => {
	const params = useParams()
	const [getTasks] = useLazyGetProjectTaskApiQuery()
	const navigate = useNavigate()
	const { progress } = useSelector(state => state.progress)
	const [isLoading, setIsLoading] = useState(false)
	const [team, setTeam] = useState()
	const [currentStatus, setCurrentStatus] = useState()
	const [dropdownPosition, setDropdownPosition] = useState()
	const [dropdownStatus, setDropdownStatus] = useState()
	const wrapperRef = useRef()

	const getTaskInfo = taskId => {
		GetTaskByTaskIdApi(navigate, taskId)
			.then(
				res =>
					res && res?.data && res?.data?.teams && setTeam(res?.data?.teams[0])
			)
			.catch(error => toast.error(error?.message))
	}

	useEffect(() => {
		setCurrentStatus(data)
	}, [data])

	useEffect(() => {
		if (
			window.innerHeight - 80 <
			wrapperRef.current.getBoundingClientRect().bottom + 170
		)
			setDropdownPosition('bottom')
		else setDropdownPosition('top')
	}, [dropdownStatus])

	useEffect(() => {
		position === 'searchResult' && getTaskInfo(parentDetail.id)
	}, [position])

	const changeStatus = async _progress => {
		setIsLoading(true)
		setCurrentStatus(_progress)
		try {
			let response = await ChangeTaskProgressApi(navigate, {
				task_id: parentDetail?.id,
				team_id:
					position === 'searchResult'
						? team?.team_id
						: parentDetail?.teams[0]?.team_id,
				progress_id: _progress?.id
			})
			bigSize && (await getTasks({ projectId: params?.projectId }))
			setIsLoading(false)
		} catch (error) {
			setCurrentStatus(data)
			setIsLoading(false)
			toast.error(error.message)
		}
	}

	return (
		<div
			className='shrink-0 relative group z-1000'
			ref={wrapperRef}
			onMouseOver={() => setDropdownStatus(true)}
			onMouseLeave={() => setDropdownStatus(false)}>
			{bigSize ? (
				<div
					className={`bg-gray-300 rounded-[10px] border border-solid flex-col justify-center px-[10px] flex items-center gap-4 relative h-[71px] 
				border-red-500
					`}>
					<div
						className={`flex items-center  justify-center  text-sm gap-6 text-red-500
						`}>
						{currentStatus?.name}
						<ArrowIcon color='currentColor' width={10} height={6} />
						{isLoading && (
							<div className='absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-50'>
								<Spinner scale={20} show size={3} />
							</div>
						)}
					</div>
				</div>
			) : (
				<div
					className={`h-10 relative ${
						!isSubtask && 'bg-gray-200 '
					} rounded-[10px] border overflow-hidden border-solid border-gray-300 gap-3  px-4 flex items-center justify-between`}>
					<span className='text-red-700 text-sm'>{currentStatus?.name}</span>
					<ArrowIcon />
					{isLoading && (
						<div className='absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-50'>
							<Spinner scale={20} show size={3} />
						</div>
					)}
				</div>
			)}

			{dropdownStatus && (
				<div
					className={`absolute z-10 ${
						dropdownPosition === 'top' ? 'top-full' : 'bottom-full'
					}  inline-block left-1/2 -translate-x-1/2 pt-1`}>
					<ul className='bg-gray-200 shadow-xl rounded-md overflow-hidden   '>
						{progress?.map(item => (
							<li>
								<button
									onClick={() => changeStatus(item)}
									disabled={item.id === currentStatus?.id}
									className={`px-3 text-sm py-2 disabled:!text-white disabled:!bg-green-500 hover:text-white whitespace-nowrap w-full text-center  hover:bg-gray-350 duration-200`}>
									{item?.name}
								</button>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	)
}

export default ProgressDropDown
