import React from 'react'

const ArrowUpIcon = ({ color = '#3484F0', size = 20 }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 384 512'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
				fill={color}
			/>
		</svg>
	)
}

export default React.memo(ArrowUpIcon)
