import React, { useEffect, useState } from 'react'
import NoteGroupBoxItem from './NoteGroupBoxItem'
import PropTypes from 'prop-types'

const NoteGroupBox = ({
	NoteModalHandler,
	data,
	inModal,
	isAggregate = false
}) => {
	const [mainData, setMainData] = useState('loading')
	const [date, setDate] = useState([])

	useEffect(() => {
		groupHandler()
	}, [data])

	const groupHandler = () => {
		if (data && data.length) {
			const dataWithDate = data.map(item => ({
				...item,
				date: item.created_at.split('T')[0]
			}))
			const result = dataWithDate.reduce((prev, next) => {
				;(prev[next.date] = prev[next.date] || []).push(next)

				return prev
			}, {})
			setDate(Object.keys(result).map(item => item))
			setMainData(result)
		} else {
			setMainData([])
		}
	}
	return (
		<div className='overflow-visible custom_scroll'>
			<table className='w-full'>
				<thead>
					<tr className='flex  text-gray-700 text-opacity-80 font-medium '>
						<th className='w-[15%] border-t border-x rounded-tl-[5px] border-gray-200 border-solid bg-gray-300 justify-between  h-10 flex items-center px-[10px]'>
							Creator
						</th>
						<th className='w-[45%] border-t border-r  border-gray-200 border-solid bg-gray-300 justify-between  h-10 flex items-center px-[10px]'>
							Title
						</th>
						<th className='w-[25%] border-t border-r  border-gray-200 border-solid bg-gray-300 justify-between  h-10 flex items-center px-[10px]'>
							Created At
						</th>
						<td className='w-[15%] '></td>
					</tr>
				</thead>
				<tbody>
					{date.map(item => (
						<div>
							{mainData[item].map((item, index) => (
								<NoteGroupBoxItem
									isAggregate={isAggregate}
									inModal={inModal}
									key={'note_item' + index}
									data={item}
									NoteModalHandler={NoteModalHandler}
								/>
							))}
						</div>
					))}
				</tbody>
			</table>
		</div>
	)
}

NoteGroupBox.propTypes = {
	data: PropTypes.array,
	NoteModalHandler: PropTypes.func.isRequired,
	inModal: PropTypes.bool
}
NoteGroupBox.defaultProps = {
	NoteModalHandler: null,
	data: [],
	inModal: false
}

export default NoteGroupBox
