import CheckboxIcon from 'components/Icons/CheckboxIcon'
import CubeIcon from 'components/Icons/CubeIcon'
import PencilIcon from 'components/Icons/PencilIcon'
import TrashcanIcon from 'components/Icons/TrashcanIcon'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLazyGetAllNotesApiQuery } from 'redux/api/note'
import { editModalHandler } from 'redux/slices/originModals'
import { DeleteNoteApi } from 'services/dashboardServices/note'
import NoteGroupBoxItemDeleteModal from './NoteGroupBoxItemDeleteModal'
import DefaultImage from 'components/Common/DefaultImage'
import { toast } from 'react-toastify'
import moment from 'moment'

const NoteGroupBoxItem = ({ NoteModalHandler, data, inModal, isAggregate }) => {
	const params = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [modalCondition, setModalCondition] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [getNotes] = useLazyGetAllNotesApiQuery()

	const deleteNote = async () => {
		setIsLoading(true)
		try {
			await DeleteNoteApi(navigate, data.id)
			if (params.projectId) {
				await getNotes({ projectId: params?.projectId })
			} else {
				await getNotes({})
			}
			setModalCondition(false)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			toast.error(error.message)
		}
	}

	return (
		<>
			<tr className='flex last:border-b first:rounded-tr-[5px] border-x border-t border-solid border-gray-200 '>
				<td className='w-[15%] border-r border-solid border-gray-200 justify-center  h-14 flex items-center px-[10px]'>
					<div class='relative group'>
						<DefaultImage name={data?.created_by.name} />
						<h5 class='bg-gray-300 shadow-custom rounded-[10px] absolute left-1/2 transform -translate-x-1/2 top-full hidden group-hover:block text-sm mt-1 z-10 whitespace-nowrap px-2 py-1'>
							{data?.created_by.name ? data?.created_by.name : 'Unknown'}
						</h5>
					</div>
				</td>
				<td className='w-[45%] truncate border-r border-solid border-gray-200 opacity-80  h-14 flex items-center px-[10px]'>
					{data?.title}
				</td>
				<td className='max-md:text-sm w-[25%] border-r border-solid border-gray-200 opacity-80  h-14 flex items-center px-[10px]'>
					<span className='text-left'>
						<span className='md:inline hidden'>
							{moment(data?.created_at).format('LL')} -{' '}
							{moment(data?.created_at).format('LT')}
						</span>
						<span className='md:hidden inline'>
							{moment(data?.created_at).format('L')} -{' '}
							{moment(data?.created_at).format('LT')}
						</span>
					</span>
				</td>
				<td className='w-[15%] border-r border-solid border-gray-200  justify-around text-primaryBlue h-14 flex items-center px-[10px]'>
					{NoteModalHandler ? (
						<button
							onClick={() => NoteModalHandler(data)}
							className='w-10 h-10 flex items-center justify-center bg-gray-300 rounded-[10px]'>
							<PencilIcon />
						</button>
					) : (
						<button
							onClick={() =>
								dispatch(editModalHandler({ modal: 'note', value: data }))
							}
							className='w-10 h-10 flex items-center justify-center bg-gray-300 rounded-[10px]'>
							<PencilIcon />
						</button>
					)}

					<button
						onClick={() => setModalCondition(true)}
						className='w-10 h-10 flex items-center justify-center bg-gray-300 rounded-[10px]'>
						<TrashcanIcon />
					</button>
				</td>
			</tr>

			{modalCondition && (
				<NoteGroupBoxItemDeleteModal
					isLoading={isLoading}
					deleteHandler={deleteNote}
					modalHandler={() => setModalCondition(false)}
				/>
			)}
		</>
	)
}

export default NoteGroupBoxItem
