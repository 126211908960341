import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ModalContainer from 'components/ModalContainer/ModalContainer'
import DocumentFillIcon from 'components/Icons/DocumentFillIcon'
import XIcon from 'components/Icons/XIcon'
import FullScreenIcon from 'components/Icons/FullScreenIcon'
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useWindowSize } from 'hooks/useWindowSize'
import { AddNoteApi, EditNoteApi } from 'services/dashboardServices/note'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetProjectsByOrgIdApiQuery } from 'redux/api/projects'
import { useLazyGetAllMilestoneByProjectIdApiQuery } from 'redux/api/milestone'
import { useLazyGetProjectTaskApiQuery } from 'redux/api/task'
import { useLazyGetAllNotesApiQuery } from 'redux/api/note'
import { toast } from 'react-toastify'
import Spinner from 'components/Common/Spinner'
import { useLazyGetAllMeetingsApiQuery } from 'redux/api/meeting'
import Input from 'components/Common/Input'
import SelectBox from 'components/Common/SelectBox'
import TextEditor from 'components/Common/TextEditor'

const AddNoteOfTaskDetailModal = ({ modalHandler, editData, taskDetail,updateList }) => {
	const params = useParams()
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const [fullScreen, setFullScreen] = useState(false)
	const [errors, setErrors] = useState()
	const [width] = useWindowSize()
	const { data: projects } = useGetProjectsByOrgIdApiQuery()
	const [getMilestone, { data: milestones }] =
		useLazyGetAllMilestoneByProjectIdApiQuery()
	const [getTasks, { data: tasks }] = useLazyGetProjectTaskApiQuery()
	const [getMeetings, { data: meetings }] = useLazyGetAllMeetingsApiQuery()

	const changeDefaultItem = data => {
		return data ? { ...data, label: data?.name, value: data?.id } : null
	}

	const [dataSchema, setDataSchema] = useState({
		id: null,
		title: 'Untitled Note',
		body: null,
		milestone_id: changeDefaultItem(taskDetail?.milestone),
		task_id: changeDefaultItem(taskDetail),
		meeting_id: null
	})
	useEffect(() => {
		getMeetings()
		getMilestone({ projectId: taskDetail?.project?.id })
		getTasks({
			milestoneId: taskDetail?.milestone?.id,
			projectId: taskDetail?.project?.id
		})
	}, [])

	const dataSchemaHandler = (_key, _value) => {
		setDataSchema({ ...dataSchema, [_key]: _value })

		if (errors && errors[_key]) {
			const data = errors
			delete data[_key]
			setErrors(data)
		}
	}

	const addNote = async () => {
		setIsLoading(true)
		let newDataSchema = {
			...dataSchema,
			milestone_id: dataSchema?.milestone_id?.id,
			project_id: taskDetail?.project?.id,
			task_id: taskDetail?.id
		}
        
		try {
			const response = editData
				? await EditNoteApi(navigate, newDataSchema)
				: await AddNoteApi(navigate, newDataSchema)

			if (response.status === 422) {
				setErrors(response.data.errors)
			} else {
				await updateList()
				toast.success('note added successfully')
				modalHandler(false)
			}

			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log(error)
		}
	}
	return (
		<ModalContainer
			rounded={10}
			maxHeight={width <= 768 ? true : false}
			setShowModal={modalHandler}>
			<div
				className={` md:bg-white bg-gray-300  md:h-fit h-full w-screen md:px-10 px-4 md:pt-8 md:pb-8 pt-3 pb-[180px] ${
					fullScreen
						? 'fixed top-0 right-0 w-screen h-screen'
						: ' md:w-[1264px] md:max-w-[98vw]'
				}`}>
				<div className='flex items-center justify-between mb-4'>
					<div className='flex items-center '>
						<span className='text-primaryBlue mr-3'>
							<DocumentFillIcon />
						</span>
						<Input
							className={'!bg-white !text-lg'}
							height={54}
							value={dataSchema?.title}
							defaultValue={
								dataSchema?.title ? dataSchema?.title : 'Untitled Note'
							}
							errors={errors?.title}
							type={'text'}
							required={true}
							name={'title'}
							onInput={dataSchemaHandler}
							placeholder='Note Title'
						/>
					</div>

					<div className='flex items-center gap-4'>
						<button className='text-xs font-light md:flex hidden text-gray-400'>
							Saved
						</button>
						<button
							onClick={() => setFullScreen(!fullScreen)}
							className='h-9 border md:flex hidden whitespace-nowrap border-solid border-primaryBlue hover:bg-primaryBlue hover:text-white duration-300 text-primaryBlue font-light text-xs rounded-[5px]  items-center gap-1.5 px-4'>
							<FullScreenIcon />
							<span className='flex -mb-0.5'>
								{fullScreen ? 'base screen' : 'Full screen'}
							</span>
						</button>
						<button
							disabled={isLoading}
							onClick={addNote}
							className='md:h-9 h-[52px] flex items-center gap-3 z-10 md:relative fixed left-0 bottom-0 w-full md:bg-transparent bg-primaryBlue  border px-6 hover:bg-primaryBlue hover:text-white border-solid border-primaryBlue md:text-primaryBlue text-white font-light text-xs md:rounded-[5px]'>
							Save changes
							<Spinner show={isLoading} scale={25} size={3} />
						</button>
						<button
							onClick={modalHandler}
							className='text-opacity-60 text-gray-700 w-10 h-10 md:w-fit md:h-fit flex items-center justify-center rounded md:bg-transparent bg-gray-200'>
							<XIcon color='currentColor' />
						</button>
					</div>
				</div>

				<div className='bg-gray-300 md:border border-solid mb-4 px-3 md:rounded-[10px] py-4 border-gray-200 '>
					<TextEditor
						mode='advanced'
						onChange={dataSchemaHandler}
						height={400}
						name={'body'}
						value={editData?.body}
					/>
				</div>
				<div className='bg-gray-300 border border-solid p-4 md:relative fixed md:bottom-0 md:right-0 bottom-[52px] right-0 w-full  md:rounded-[10px] grid md:grid-cols-3 grid-cols-2 gap-4   border-gray-200 '>
					{/* <div className='custom_select_box custom_select_box_open_top'>
						<ReactSelect
							options={projects}
							value={dataSchema?.project_id}
							onChange={e => {
								setDataSchema({ project_id: e })
								getMilestone({ projectId: e.id })
							}}
							isSearchable={false}
							placeholder='Select Project'
						/>
					</div> */}

					<SelectBox
						options={meetings}
						value={dataSchema?.meeting_id}
						height={44}
						placeholder='Select Meeting'
						name={'meeting_id'}
						errors={errors?.meeting_id}
						onChange={dataSchemaHandler}
						isSearchable={false}
						className={'custom_select_box_open_top'}
					/>
					<SelectBox
						options={milestones}
						value={dataSchema?.milestone_id}
						height={44}
						isDisabled
						placeholder='Select Milestone'
						name={'milestone_id'}
						errors={errors?.milestone_id}
						onChange={(_, e) => {
							dataSchemaHandler('milestone_id', e)
							e?.id &&
								getTasks({
									milestoneId: e.id,
									projectId: params.projectId
								})
						}}
						isSearchable={false}
						className={'custom_select_box_open_top'}
					/>

					<SelectBox
						options={tasks}
						value={dataSchema?.task_id}
						height={44}
						isDisabled
						placeholder='Select Task'
						name={'task_id'}
						errors={errors?.task_id}
						onChange={dataSchemaHandler}
						isSearchable={false}
						className={'custom_select_box_open_top'}
					/>
				</div>
			</div>
		</ModalContainer>
	)
}

AddNoteOfTaskDetailModal.propTypes = {
	modalHandler: PropTypes.func.isRequired
}
AddNoteOfTaskDetailModal.defaultProps = {
	modalHandler: () => {}
}

export default AddNoteOfTaskDetailModal
