import { Component } from 'react'

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = { error: null, errorInfo: null, hasError: false }
	}
	static getDerivedStateFromError(error) {
		return { hasError: true, error }
	}
	componentDidCatch(error, errorInfo) {
		console.log('error: ', error)
		console.log('error info: ', errorInfo)
		this.setState({ ...this.state, errorInfo })
	}
	render() {
		const { hasError } = this.state
		const { children } = this.props
		if (hasError) {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100vw',
						height: '100vh'
					}}>
					<h1 style={{ fontSize: '40px' }}>Something went wrong!</h1>
				</div>
			)
		}
		return children
	}
}

export default ErrorBoundary
