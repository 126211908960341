import React, { useState, useEffect, useRef } from 'react'
import ErrorDisplay from './ErrorDisplay'
import DatePicker from 'react-multi-date-picker'
import CalendarFillIcon from 'components/Icons/CalendarFillIcon'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'

const Input = ({
	className,
	WrapperClassName,
	onInput,
	label,
	height,
	name,
	errors,
	placeholder,
	defaultValue,
	required,
	type = 'text',
	value,
	withTime,
	format = 'YYYY-MM-DD HH:mm:ss',
	iconLeft,
	customIcon,
	disabled,
	onlyTime = false,
	minDate
}) => {
	const datePickerRef = useRef(null)
	const [selectedDate, setSelectedDate] = useState(defaultValue || null)

	useEffect(() => {
		if (type === 'date' && defaultValue) {
			setSelectedDate(new Date(defaultValue))
		}
	}, [defaultValue, type])

	useEffect(() => {
		const handleClickOutside = event => {
			if (
				datePickerRef.current &&
				!datePickerRef.current.contains(event.target)
			) {
				datePickerRef.current.closeCalendar()
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleDateChange = date => {
		if (!date) return
		setSelectedDate(date)
		onInput(name, date.format(format))
	}

	if (type === 'date') {
		return (
			<div className={WrapperClassName}>
				{label && (
					<label className='font-light flex text-gray-700 text-sm mb-0.5'>
						{label}
					</label>
				)}

				<div
					ref={datePickerRef}
					style={{ height: height + 'px' }}
					className={`relative custom_date_picker border border-solid rounded-[10px] ${
						disabled && 'bg-gray-330'
					} ${iconLeft && 'custom_date_picker_left_icon'} ${
						errors
							? 'border-red-700 bg-red-500 bg-opacity-10'
							: 'bg-gray-300 border-gray-200'
					}`}>
					<DatePicker
						ref={datePickerRef}
						onChange={handleDateChange}
						format={format}
						disableDayPicker={onlyTime}
						disabled={disabled}
						plugins={withTime ? [<TimePicker position='bottom' />] : []}
						placeholder='Choose from Calendar'
						value={selectedDate}
						calendarPosition='top-center'
						fixRelativePosition={true}
						minDate={minDate}
					/>
					<span
						className={`absolute text-gray-350 ${
							iconLeft ? 'left-6' : 'right-6'
						}  top-1/2 -translate-y-1/2`}>
						{customIcon || <CalendarFillIcon />}
					</span>
				</div>
				<ErrorDisplay error={errors} />
			</div>
		)
	}
	if (type === 'textarea') {
		return (
			<div className={WrapperClassName}>
				{label && (
					<label className='font-light flex text-gray-700 text-sm mb-0.5'>
						{label}
					</label>
				)}

				<textarea
					onInput={e => onInput(name, e.target.value)}
					required={required}
					disabled={disabled}
					style={{ height: height + 'px' }}
					className={`${className} px-5 w-full disabled:!bg-gray-330 resize-none border border-solid placeholder:text-gray-350 placeholder:text-xs text-xs placeholder:text-opacity-50 rounded-[10px] ${
						errors
							? 'border-red-700 bg-red-500 bg-opacity-10'
							: 'bg-gray-300 border-gray-200'
					}`}
					defaultValue={defaultValue}
					value={value}
					placeholder={placeholder}></textarea>
				<ErrorDisplay error={errors} />
			</div>
		)
	}
	return (
		<div className={WrapperClassName}>
			{label && (
				<label className='font-light flex text-gray-700 text-sm mb-0.5'>
					{label}
				</label>
			)}

			<div className='relative'>
				<input
					onInput={e => onInput(name, e.target.value)}
					required={required}
					value={value}
					style={{ height: height + 'px' }}
					type={type}
					className={`${className} px-5 ${
						iconLeft && 'pl-[52px]'
					} w-full border border-solid placeholder:text-gray-350 placeholder:text-xs text-xs placeholder:text-opacity-50 rounded-[10px] ${
						errors
							? 'border-red-700 bg-red-500 bg-opacity-10'
							: 'bg-gray-300 border-gray-200'
					}`}
					defaultValue={defaultValue}
					placeholder={placeholder}
					disabled={disabled}
				/>
				{customIcon && (
					<span
						className={`absolute text-gray-350 ${
							iconLeft ? 'left-6' : 'right-6'
						}  top-1/2 -translate-y-1/2`}>
						{customIcon}
					</span>
				)}
			</div>
			<ErrorDisplay error={errors} />
		</div>
	)
}

export default Input
