import ListZeroPage from 'components/Common/ListZeroPage'
import AddLogModal from 'components/Common/Modals/AddLogModal'
import MagnifierIcon from 'components/Icons/MagnifierIcon'
import PlusBoxIcon from 'components/Icons/PlusBoxIcon'
import ArrowUpIcon from 'components/Icons/ArrowUpIcon'
import ArrowDownIcon from 'components/Icons/ArrowDownIcon'
import React, { useEffect, useState } from 'react'
import { useLazyGetLogByTaskIdQuery } from 'redux/api/log'

function TaskDetailLogContent({ parentData }) {
	let [getLogs, { data: logs, isLoading, isFetching }] =
		useLazyGetLogByTaskIdQuery()
	let timer = ''
	const [searchValue, setSearchValue] = useState('')
	const [sortOrder, setSortOrder] = useState('ascending')

	useEffect(() => {
		getLogs({
			taskId: parentData?.id,
			search: searchValue
		})
	}, [searchValue])

	const searchHandler = e => {
		let {
			target: { value }
		} = e

		clearTimeout(timer)

		timer = setTimeout(() => {
			setSearchValue(value)
		}, 500)
	}
	const [logModalCondition, setLogModalCondition] = useState(false)

	const toggleSortOrder = () => {
		setSortOrder(prevOrder => prevOrder === 'ascending' ? 'descending' : 'ascending')
	}

	const sortedLogs = logs ? [...logs].sort((a,b) => {
		const dateA = new Date(a.start_datetime).getTime()
		const dateB = new Date(b.start_datetime).getTime()
		return sortOrder === 'ascending' ? dateA - dateB : dateB - dateA
	}) : [];

	return (
		<div>
			<div className='flex  mb-4 gap-[7px]'>
				<button
					onClick={() => setLogModalCondition(true)}
					className='flex flex-shrink-0 md:h-11 bg-white h-[52px] left-0 z-10 md:relative fixed md:bottom-0 bottom-[75px] md:w-fit md:bg-transparent  w-full md:rounded-[5px] px-6 justify-center gap-1.5 items-center border border-solid border-primaryBlue md:text-primaryBlue text-white font-medium text-base'>
					<span className='md:inline-block hidden'>
						<PlusBoxIcon />
					</span>{' '}
					<span className='-mb-0.5'>New Log</span>
				</button>
				<div className='w-full relative'>
					<input
						onInput={searchHandler}
						className='h-11  border border-solid border-gray-200 px-5 w-full placeholder:text-gray-350 placeholder:text-xs pl-[50px] placeholder:text-opacity-50 rounded-[10px]'
						placeholder='Search Logs'
					/>
					<span className='absolute text-gray-350 left-4 top-1/2 -translate-y-1/2'>
						<MagnifierIcon />
					</span>
				</div>
				<button
					onClick={toggleSortOrder}
					className='flex flex-shrink-0 md:h-11 bg-white h-[52px] left-0 z-10 md:relative fixed md:bottom-0 bottom-[75px] md:bg-transparent  md:rounded-[5px] px-6 justify-end gap-1.5 items-center border border-solid border-primaryBlue md:text-primaryBlue text-white font-medium text-base w-[172px] z-auto'>
					<span className='-mb-0.5'>
						{sortOrder === 'ascending' ? 'Ascending' : 'Descending'}
					</span>
					<span className='md:inline-block hidden'>
						{sortOrder === 'ascending' ? <ArrowUpIcon /> : <ArrowDownIcon />}
					</span>
				</button>
			</div>
			<div className='grid gap-[5px]'>
				<ListZeroPage isLoading={isLoading} data={sortedLogs} isFetching={isFetching}>
					{sortedLogs?.map(item => (
						<div className='bg-white border border-solid overflow-hidden border-gray-200 rounded-[10px] min-h-[82px] flex'>
							<div className='bg-primaryBlue bg-opacity-5 px-4 py-2 grid justify-center items-center'>
								<span className='px-7 rounded-[10px] flex items-center justify-center mb-1.5 h-10 bg-white text-primaryBlue font-semibold'>
									{item?.duration}
								</span>
								<div className='flex items-center justify-between font-light text-[10px] text-gray-400'>
									<span>{item?.start_datetime.split(' ')[0]}</span>
									<span>{item?.start_datetime.split(' ')[1]}</span>
								</div>
							</div>
							<div className='flex flex-col items-start justify-center p-4'>
								<div className='flex items-center gap-[5px] '>
									<img className='w-[38px] h-[38px] rounded-full' src={item?.team.avatar} alt=''/>
									<span className='text-sm text-gray-4'>{item?.team.first_name}</span>
									<span className='text-sm text-gray-4 font-light'>{item?.team.last_name}</span>
								</div>
								<p className='text-sm font-light text-gray-700 text-opacity-80 max-w-[550px]'>
									<span className='h-[22px] mr-2 rounded-md font-light text-gray-400 bg-gray-300 text-[10px]'>
										{item?.milestone?.name}
									</span>
									{item?.description}
								</p>
							</div>
							
						</div>
					))}
				</ListZeroPage>
			</div>

			{logModalCondition && (
				<AddLogModal
					taskDetail={parentData}
					handler={() => setLogModalCondition(false)}
					refreshData={() => getLogs({taskId: parentData?.id,
						search: searchValue})}
				/>
			)}
		</div>
	)
}

export default TaskDetailLogContent
