import useOutsideClick from 'hooks/useOutsideClick'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import './ModalContainer.scss'
import { useDispatch } from 'react-redux'
import { triggerModalClosed } from 'redux/slices/project'

const ModalContainer = ({
	children,
	setShowModal,
	rounded,
	maxHeight,
	overflowHidden = true
}) => {
	const dispatch = useDispatch()
	const wrapperRef = useRef(null)
	const isClickedOutside = useOutsideClick(wrapperRef)
	useEffect(() => {
		dispatch(triggerModalClosed(isClickedOutside))
		setTimeout(() => {
			isClickedOutside && setShowModal()
		}, 500);
	}, [isClickedOutside, setShowModal])
	return (
		<div 
			onClick={e => e.stopPropagation()} 
			className='ModalContainer custom_scroll !z-[9999999]'
		>
			<div
				className={` bg-white min-w-1/2
				${overflowHidden && 'overflow-auto'}
				 ${maxHeight
						? 'max-h-screen md:h-screen h-fit fixed right-0 top-0'
						: 'md:max-h-[90vh] h-fit'
					}  ${rounded ? 'md:rounded-[10px]' : ''}`}
				ref={wrapperRef}>
				{children}
			</div>
		</div>
	)

}

ModalContainer.propTypes = {
	children: PropTypes.object.isRequired,
	setShowModal: PropTypes.func.isRequired,
	rounded: PropTypes.bool,
	maxHeight: PropTypes.bool
}

ModalContainer.defaultProps = {
	children: {},
	rounded: true,
	maxHeight: false,
	setShowModal: () => { }
}

export default ModalContainer
